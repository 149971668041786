import React, { useState, useEffect } from 'react';
import { getText } from '../i18n';
import pfp from '../assets/imgs/main_image_compressed.jpg';
import '../App.css'

const Home = ({ lang }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="home" className='w-screen h-screen bg-background overflow-hidden relative scroll-smooth'>
      {/* <Navbar lang={lang} /> */}
      <div className='flex flex-col md:flex-row justify-between items-center h-full mt-16'>
        <div className='ml-6 text-left w-11/12 md:w-1/2 p-8 space-y-4'>
          {/* <div className='text-secondary text-xl mb-2 '>{getText(lang, 'home', 'welcome')}</div> */}
          <div className='text-secondary text-xl mb-2 '>
            {getText(lang, 'home', 'welcome')}
          </div>

          <div className='text-primary text-5xl  md:text-8xl font-bold mb-4 leading-tight'>
            {getText(lang, 'home', 'title')}
          </div>

          <div className='text-primary text-2xl md:text-5xl mb-4'>{getText(lang, 'home', 'subtitle')}</div>
          {/* <a href="#contact" className="inline-flex mt-6 items-center justify-center h-12 px-6 py-0 text-lg text-center text-gray-200 
          no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-gray-600 border-solid 
          rounded-full cursor-pointer select-none hover:text-white hover:border-white focus:shadow-xs focus:no-underline">
            {getText(lang, 'home', 'contact')}
          </a> */}
          <a href="#contact">
            <button className='buttong mt-5'>
              {getText(lang, 'home', 'contact')}
            </button>
          </a>

        </div>

        <div className='relative md:w-1/2 w-full h-1/2 md:h-full flex justify-center md:justify-end items-center'>
          <div className='relative w-full h-full'>
            <img loading="lazy" src={pfp} alt="Profile" className='w-full h-full object-cover md:object-cover z-0 -mt-16' />
            <div className='absolute bottom-15 md:bottom-20 right-1/2 md:right-4 transform translate-x-1/2 md:translate-x-0 w-full flex justify-center'>
              <div className="available-indicator border border-text">
                <span className="light"></span>
                {getText(lang, 'home', 'avaliable')}
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* {!isScrolled && (
        <div className="field absolute bottom-0 w-full">
          <div className="scroll"></div>
        </div>
      )} */}
    </div>
  );
};

export default Home;
