import React from 'react';

const ReviewCard = ({ imageSrc, name, rating, review }) => (
  <div className="bg-background2 p-5 rounded-lg shadow-md flex flex-col max-w-96 justify-start items-start">
    <div className='flex justify-start items-start'>
      <img loading="lazy" src={imageSrc} alt={`${name}'s profile`} className="w-12 h-12 rounded-full mx-auto" />
      <div className='flex flex-col justify-start items-start ml-5'>
        <h2 className="text-xl font-bold text-center text-primary">{name}</h2>
        <div className="flex justify-center space-x-0.5">
          {Array(rating).fill().map((_, i) => (
            <span key={i} className="text-secondary text-xl">★</span>
          ))}
        </div>
      </div>
    </div>
    <div>
      <p className="mt-4 text-start text-text line-clamp-3">{review}</p>
    </div>
  </div>
);

export default ReviewCard;
