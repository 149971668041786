import React, { useState } from 'react';
import './App.css';
import Home from './sections/Home';
import AboutMe from './sections/AboutMe';
import Projects from './sections/Projects';
import History from './sections/History';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import Navbar from './components/Navbar';
import AnimatedReviewsCloud from './components/AnimatedReviewsCloud';
import Services from './sections/Services';
import AnimatedStats from './components/AnimatedStats';

function App() {
  const [lang, setLang] = useState('en'); // Set default language to English

  return (
    <div>
      <Navbar lang={lang} setLang={setLang} />
      <Home lang={lang} />
      <AboutMe lang={lang} />
      <AnimatedStats lang={lang} />
      <Services lang={lang} />
      <Projects lang={lang} />
      {/* <History lang={lang} /> */}
      <Contact lang={lang} />
      <AnimatedReviewsCloud lang={lang} />
      <Footer lang={lang} />
    </div>
  );
}

export default App;
