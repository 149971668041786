import React from 'react';
import ReviewCard from '../components/ReviewCard';
import pfp from '../assets/imgs/user1.jpeg';
import pfp2 from '../assets/imgs/user2.jpg';
import pfp3 from '../assets/imgs/user3.jpeg';

const reviews = [
  {
    imageSrc: pfp,
    name: "Francisco Ferreira",
    rating: 5,
    review: "Yasser es un desarrollador con una muy buena capacidad para el backend. Durante nuestro proyecto, demostró una gran profesionalidad y un profundo conocimiento en las tecnologías utilizadas."
  },
  {
    imageSrc: pfp2,
    name: "Gagandeep Dass",
    rating: 5,
    review: "Siempre demuestra una actitud positiva, lo cual ayuda a afrontar los problemas e inconvenientes que nos topamos durante los proyectos"
  },
  {
    imageSrc: pfp3,
    name: "Edward",
    rating: 5,
    review: "Did an awesome job! skilled and easy to work with, delivered exactly what I needed. Highly recommend!"
  }
];

const AnimatedReviewsCloud = () => {
  return (
    <div className="w-full p-2 bg-background">
      <div className="mx-auto w-full px-1 md:px-8">
        <div
          className="group relative flex gap-6 overflow-hidden p-2"
          style={{
            maskImage:
              'linear-gradient(to left, transparent 0%, black 20%, black 80%, transparent 95%)',
          }}
        >
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className="flex shrink-0 animate-logo-cloud flex-row justify-around gap-6"
              >
                {reviews.map((review, key) => (
                  <ReviewCard
                    key={key}
                    imageSrc={review.imageSrc}
                    name={review.name}
                    rating={review.rating}
                    review={review.review}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AnimatedReviewsCloud;
