import React from 'react';
import ServiceCard from '../components/ServiceCard';
import { getText } from '../i18n';

const Services = ({ lang }) => {
  const services = [
    {
      title: getText(lang, 'services', 'webtitle'),
      description: getText(lang, 'services', 'webdescription'),
    },
    {
      title: getText(lang, 'services', 'landingtitle'),
      description: getText(lang, 'services', 'landingdescription'),
    },
    {
      title: getText(lang, 'services', 'ecommercetitle'),
      description: getText(lang, 'services', 'ecommercedescription'),
    },
  ];

  return (
    <div id='services' className="w-screen h-auto py-16 px-5 bg-background">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-secondary text-4xl md:text-5xl font-bold mb-8">{getText(lang, 'services', 'title')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
        <div className="mt-12 text-center">
          <p className="text-text text-lg">{getText(lang, 'services', 'need')} <a href="#contact" className="text-secondary hover:underline"> {getText(lang, 'services', 'letme')} </a></p>
        </div>
      </div>
    </div>
  );
};

export default Services;
