import React from 'react';
import { getText } from '../i18n';

const ProjectCard = ({ lang, imageSrc, title }) => {
  return (
    <div className="flex flex-col md:flex-row justify-start items-start w-full mt-8">
      <div className='md:w-1/4 w-full'>
        <img loading="lazy" src={imageSrc} alt={title} className="w-full h-auto object-cover rounded-md" />
      </div>
      <div className='flex flex-col md:w-3/4 w-full justify-start items-start mt-5 md:mt-0 md:ml-5 p-5'>
        <h3 className='text-secondary text-4xl font-bold'>{getText(lang, 'projects', 'subtitle')}</h3>
        <h3 className='text-white text-2xl mt-3'>{getText(lang, 'projects', 'subtitle2')}</h3>
        <h3 className='text-white text-opacity-90 mt-3 leading-6'>{getText(lang, 'projects', 'projectdesc')}</h3>
      </div>
    </div>
  );
};

export default ProjectCard;
