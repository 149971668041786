import React from 'react';
import { getText } from '../i18n';
import LanguageCard from '../components/LanguageCard';
import { FaNodeJs, FaReact, FaAws, FaVuejs, FaJava } from "react-icons/fa";
import { SiMongodb, SiExpress } from "react-icons/si";
import TimelineItem from '../components/TimelineItem';
import { IoIosCloudOutline } from "react-icons/io";
import { SiFreelancer } from "react-icons/si";

const AboutMe = ({ lang }) => {
  return (
    <div id='about' className="w-screen min-h-fit bg-background2 p-6 md:p-10">
      <div className="max-w-screen-xl mx-auto mt-8 md:mt-12 mb-8 md:mb-12">
        <h2 className="text-secondary text-4xl md:text-5xl font-bold mb-4">{getText(lang, 'about', 'title')}</h2>
        <p className="text-white text-base md:text-lg md:leading-8">{getText(lang, 'about', 'description')}</p>


        {/* <h2 className="text-secondary text-3xl md:text-5xl font-bold mb-6 md:mb-14">{getText(lang, 'history', 'title')}</h2> */}
        <h3 className="text-secondary text-xl md:text-3xl mt-8 md:mt-8">{getText(lang, 'history', 'title')}</h3> 

        <div className="relative mt-8">
          <div className="absolute w-1 bg-secondary h-full left-6 transform -translate-x-1/2 md:ml-2   md:block"></div>
          <div className="space-y-8 mb-8 md:-translate-x-16">
            <TimelineItem
              Icon={IoIosCloudOutline}
              date={getText(lang, 'history', 'date1')}
              title={getText(lang, 'history', 'title1')}
              description={getText(lang, 'history', 'desc1')}
            />
            <TimelineItem
              Icon={FaNodeJs}
              date={getText(lang, 'history', 'date2')}
              title={getText(lang, 'history', 'title2')}
              description={getText(lang, 'history', 'desc2')}
            />
            <TimelineItem
              Icon={FaJava}
              date={getText(lang, 'history', 'date3')}
              title={getText(lang, 'history', 'title3')}
              description={getText(lang, 'history', 'desc3')}
            />
            <TimelineItem
              Icon={SiFreelancer}
              date={getText(lang, 'history', 'date4')}
              title={getText(lang, 'history', 'title4')}
              description={getText(lang, 'history', 'desc4')}
            />
          </div>
        </div>


        {/* <h3 className="text-secondary text-xl md:text-2xl mt-8 md:mt-10">{getText(lang, 'about', 'subtitle')}</h3> 
         <div className='grid grid-cols-1 md:grid-cols-none md:flex gap-5 mt-3'>
          <LanguageCard name="React" level={getText(lang, 'cards', 'intermediate')} Icon={FaReact} />
          <LanguageCard name="NodeJs" level={getText(lang, 'cards', 'advanced')} Icon={FaNodeJs} />
          <LanguageCard name="MongoDB" level={getText(lang, 'cards', 'advanced')} Icon={SiMongodb} />
          <LanguageCard name="Express" level={getText(lang, 'cards', 'advanced')} Icon={SiExpress} />
        </div> 
         <p className='mt-3 text-base md:text-lg text-text'>{getText(lang, 'about', 'advert')}</p>
        <h3 className="text-secondary text-xl md:text-2xl mt-8 md:mt-10">{getText(lang, 'about', 'second-subtitle')}</h3>
        <div className='grid grid-cols-1 md:grid-cols-none md:flex gap-5  mt-3'>
          <LanguageCard name="AWS" level={getText(lang, 'cards', 'basic')} Icon={FaAws} />
          <LanguageCard name="Vue" level={getText(lang, 'cards', 'basic')} Icon={FaVuejs} />
        </div> */}
      </div>
    </div>
  );
};

export default AboutMe;
