import React, { useState } from 'react';
import { getText } from '../i18n';
import ProjectCard from '../components/ProjectCard';
import velouriaImage from '../assets/imgs/velouria-preview-compressed.png';

const Projects = ({ lang }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
  };

  const projects = [
    {
      imageSrc: velouriaImage,
      title: 'Velouria',
      description: getText(lang, 'projects', 'projectdesc'),
      technologies: ['React', 'Node.js', 'MongoDB', 'Express'],
    },
  ];

  return (
    <div id="projects" className="w-screen h-auto p-6 md:p-14 bg-background2">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-white text-4xl md:text-5xl font-bold mb-4">
          {getText(lang, 'projects', 'title')}
        </h2>
        <div className='w-full flex flex-col items-center relative'>
          {projects.map((project, index) => (
            <ProjectCard 
              key={index}
              imageSrc={project.imageSrc}
              imageSrc2={project.imageSrc2}
              title={project.title}
              description={project.description}
              technologies={project.technologies}
              showMore={showMore}
              lang={lang}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
