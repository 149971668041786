import React from 'react';

const ServiceCard = ({ title, description }) => {
  return (
    <div className="bg-background2 p-6 rounded-md shadow-lg flex flex-col justify-between transform transition-transform hover:scale-105 border border-secondary mt-2">
      <div className="flex-1">
        <h3 className="text-primary text-2xl font-semibold mb-4">{title}</h3>
        <p className="text-text text-base">{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
